/* ======================================================
DEFAULTS
======================================================= */

html {
  background-color: #FFFFFF;
  font-family: sans-serif;
}

.App {
  text-align: center;
  overflow: hidden;
  font-family: sans-serif;
}
